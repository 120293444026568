





































































import { defineComponent, onMounted, PropType, provide, reactive } from "@vue/composition-api";
import { getStudent } from "@/api/customerService";
import EditStudentDialog from "@/components/customer/my-students-customer/EditStudentDialog.vue";
import DeleteStudentDialog from "@/components/customer/my-students-customer/DeleteStudentDialog.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  name: "StudentCard",
  components: {
    DeleteStudentDialog,
    EditStudentDialog,
    ProfilePhoto,
  },
  props: {
    studentUID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      deleteStudentDialogVisible: false,
      loading: false,
      placeholder: "",
      student: {} as Learnlink.Student.Student,
      updateStudentDialogVisible: false,
    });

    onMounted(async () => {
      await mount();
    });

    async function mount() {
      vm.loading = true;
      vm.student = await getStudent(props.studentUID);
      vm.loading = false;
    }

    function deleteStudent() {
      emit("deleted");
    }

    provide("getStudent", mount);

    return {
      deleteStudent,
      vm,
    };
  },
});
