

















import { userModule } from "@/store/modules/user";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import EditStudentCard from "@/components/customer/my-students-customer/EditStudentCard.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "EditStudentDialog",
  components: {
    EditStudentCard,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    student: {
      type: Object as PropType<Learnlink.Student.Student>,
      default: () => ({}),
    },
    teamID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      uid: userModule.state.userId,
      _value,
    };
  },
});
