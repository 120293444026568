
















































































import { createStudent, updateStudent } from "@/api/customerService";
import { sanitizeEmail } from "@/helpers/stringUtils";
import { isValidBirthdate } from "@/helpers/timeUtils";
import analytics from "@/services/analytics";
import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import BirthdatePicker from "@/components/settings/BirthdatePicker.vue";
import Learnlink from "@learnlink/interfaces";
import handleError, { LearnlinkError, sanitizeError } from "@/helpers/errors";
import rules from "@/helpers/formRules";

export default defineComponent({
  name: "EditStudentCard",
  components: { BirthdatePicker },
  props: {
    parentUID: {
      type: String as PropType<string>,
      required: true,
    },
    student: {
      type: Object as PropType<Learnlink.Student.Student>,
      default: () => ({}),
    },
    // If teamID is set, the user is creating a new student.
    teamID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      loading: false,
      menu: false,
      showPassword: false,
      student: {
        birthdate: props.student?.account?.birthdate || "",
        email: props.student?.account?.email || "",
        firstName: props.student?.profile?.firstName || "",
        lastName: props.student?.profile?.lastName || "",
        notes: props.student?.user?.notes || "",
        parentUID: props.parentUID,
        password: "Learnlink123",
        phone: props.student?.account?.phone || "" as string | undefined,
        teamID: props.teamID || "",
        uid: props.student?.user?.uid || "",
      } as Learnlink.Student.Create,
    });
    const notify = useNotifier();

    const getStudent = inject<() => Promise<void>>("getStudent");
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");

    const incompleteForm = computed(() => {
      return !vm.student.firstName || !vm.student.lastName || (vm.student.birthdate && !isValidBirthdate(vm.student.birthdate));
    });

    async function createNewStudent() {
      if (!incompleteForm.value) {
        const student = { ...vm.student };
        student.phone = undefined;
        student.email = sanitizeEmail(`${vm.student.firstName}.${vm.student.lastName}@learnlink.no`);
        await createStudent(student);

        notify({
          title: "Lagt til! 🥳",
          message: `${student.firstName} ble lagt til i teamet.`,
          type: "success",
        });
        analytics.track("addedParticipant");

        if (getMyTeams) await getMyTeams();
        await userModule.actions.fetchUser(userModule.state.userId);
        if (getStudent) await getStudent();

        emit("close");
      }
      else {
        notify({
          title: "Noe er ikke som det skal! 🧐",
          message: "Det virker som om det mangler noe informasjon. Se til at de obligatoriske feltene er fylt ut riktig.",
          type: "error",
        });
      }
    }

    async function updateExistingStudent() {
      if (!incompleteForm.value) {
        await updateStudent({
          birthdate: vm.student.birthdate,
          // email: vm.student.email,
          firstName: vm.student.firstName,
          lastName: vm.student.lastName,
          notes: vm.student.notes,
          // phone: vm.student.phone,
          uid: props.student?.user.uid,
        });

        await userModule.actions.fetchUser(userModule.state.userId);
        if (getStudent) await getStudent();

        notify({
          title: "Wohooo! 🥳",
          message: "Oppdateringen gikk som smurt!",
          type: "success",
        });
        emit("close");
      }
      else {
        notify({
          title: "Noe er ikke som det skal! 🧐",
          message: "Det virker som om det mangler noe informasjon. Se til at de obligatoriske feltene er fylt ut riktig.",
          type: "error",
        });
      }
    }

    async function submit() {
      vm.loading = true;
      try {
        if (props.student?.user?.uid) await updateExistingStudent();
        else await createNewStudent();
      }
      catch (e) {
        handleError(e);
        const sanitizedError = sanitizeError(e);
        notifyError(sanitizedError);
      }
      vm.loading = false;
    }

    function notifyError(e: LearnlinkError) {
      if (e.code === 409 && e.message.includes("email")) {
        notify({
          title: "E-postadressen er allerede i bruk! 🤯",
          message: "Prøv med en annen e-post, eller kontakt support dersom dette ikke stemmer.",
          type: "error",
        });
      }
      else if (e.code === 422) {
        let params: string;
        if (Array.isArray(e.message)) {
          params = e.message.map(message => {
            if (message.param === "phone") return "Telefonnummeret";
            if (message.param === "email") return "E-postadressen";
            return "";
          }).join(", ");
        }
        else {
          params = "Noe av informasjonen";
        }
        notify({
          title: params + " er ikke gyldig. 🤯",
          message: "Dobbeltsjekk at all informasjonen stemmer.",
          type: "error",
        });
      }
      else {
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Vennligst kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
    }

    return {
      incompleteForm,
      rules,
      submit,
      vm,
    };
  },
});
